import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

const resources = {
  en: {
    translation: {
      HomepageHeadline1: "We Deliver Tailor-made IT Solutions",
      HomepageHeadline2: "Grow Your Business Today",
      "Solutions and Services": "Solutions and Services",
      "Website Development": "Website Development",
      WebDevDes:
        "Craft stunning and user-friendly websites tailored to your brand, driving online success.",
      "Software Development": "Software Development",
      softdevdes:
        "Build customized software solutions to streamline your operations and boost productivity.",
      "IT Procurement": "IT Procurement",
      procurementdes:
        "Source and secure top-quality hardware and software, optimizing your IT infrastructure cost-effectively.",
      "IT Project Management": "IT Project Management",
      ITPMdes:
        "Efficiently lead and execute IT projects, ensuring on-time delivery and cost control.",
      "IT Support": "IT Support",
      ITsupdes:
        "Resolve IT issues swiftly and provide proactive support to keep your systems running smoothly.",
      "Learn more about our offering": "Learn more about our offering",
      TransLABtordes:
        "Seamlessly translate and transmit results to HL7 and ASTM formats, simplifying file-based analyzer management.",
      ReportFinderdes:
        "Effortlessly access and interact with server databases via API, offering intuitive filtering, sorting, and report downloads.",
      Home: "Home",
      Solutions: "Solutions",
      Products: "Products",
      "About Us": "About Us",
      "Contact Us": "Contact Us",
      "Who We Are": "Who We Are",
      "Technical Support": "Technical Support",
      "Sales Inquiry": "Sales Inquiry",
      Support: "Support",
      Website: "Website",
      Software: "Software",
      Distributors: "Distributors",
      OfficeRegion: "ASIA-PACIFIC OFFICE",
      HKOfficeAddress:
        "Unit 1101, 11/F., Tower 1, Cheung Sha Wan Plaza, 833 Cheung Sha Wan Road, Lai Chi Kok, Hong Kong",
      CopyRightStatement:
        "© Copyright 2023-2025 KW Informatics Limited. All rights reserved.",
      "Our website development services encompass a wide range of modern web technologies and practices, including JavaScript, React, and Next.js.":
        "Our website development services encompass a wide range of modern web technologies and practices, including JavaScript, React, and Next.js.",
      "We handle the entire process, from initial concept to deployment. This includes web hosting, domain name registration, and SSL certificate setup for a secure online presence.":
        "We handle the entire process, from initial concept to deployment. This includes web hosting, domain name registration, and SSL certificate setup for a secure online presence.",
      "Whether you need a sleek and informative website or a complex web application, we're well-equipped to bring your vision to life.":
        "Whether you need a sleek and informative website or a complex web application, we're well-equipped to bring your vision to life.",
      "Our software development expertise extends to various domains, including C language, Java, and web and Windows applications.":
        "Our software development expertise extends to various domains, including C language, Java, and web and Windows applications.",
      "We specialize in crafting custom software solutions to meet your specific needs, such as office automation, ERP systems, logistics management, and inventory control. With an emphasis on user-friendly interfaces and efficient performance, our software solutions streamline operations, increase productivity, and enhance your overall business efficiency.":
        "We specialize in crafting custom software solutions to meet your specific needs, such as office automation, ERP systems, logistics management, and inventory control. With an emphasis on user-friendly interfaces and efficient performance, our software solutions streamline operations, increase productivity, and enhance your overall business efficiency.",
      "Our IT procurement services are built on strong relationships with leading service providers and manufacturers. We're committed to sourcing the highest-quality hardware and software that align with your unique requirements. Whether you're looking for cutting-edge technology or cost-effective solutions, we'll help you secure the right IT assets for your organization.":
        "Our IT procurement services are built on strong relationships with leading service providers and manufacturers. We're committed to sourcing the highest-quality hardware and software that align with your unique requirements. Whether you're looking for cutting-edge technology or cost-effective solutions, we'll help you secure the right IT assets for your organization.",
      "Our IT project management expertise ensures the successful planning, execution, and delivery of your IT projects. We excel at coordinating resources, timelines, and budgets to bring your projects to fruition. Our rigorous project management methodologies guarantee on-time and on-budget delivery, helping you achieve your IT objectives.":
        "Our IT project management expertise ensures the successful planning, execution, and delivery of your IT projects. We excel at coordinating resources, timelines, and budgets to bring your projects to fruition. Our rigorous project management methodologies guarantee on-time and on-budget delivery, helping you achieve your IT objectives.",
      "Our IT support services offer comprehensive technical assistance and solutions to keep your systems running smoothly. From troubleshooting issues to proactive system maintenance, we've got you covered. We pride ourselves on rapid issue resolution and providing ongoing support to optimize your IT environment, so you can focus on what you do best while leaving IT worries behind.":
        "Our IT support services offer comprehensive technical assistance and solutions to keep your systems running smoothly. From troubleshooting issues to proactive system maintenance, we've got you covered. We pride ourselves on rapid issue resolution and providing ongoing support to optimize your IT environment, so you can focus on what you do best while leaving IT worries behind.",
      "About KW Informatics": "About KW Informatics",
      AboutKWInformaticsDescription:
        "At KW Informatics, we are dedicated to delivering exceptional tailor-made IT solutions that empower businesses to grow. With a passion for innovation and a commitment to excellence, we have built a strong foundation in the technology and software development industry.",
      "Our Vision": "Our Vision",
      OurVisionDescription:
        "Our vision is to empower businesses with cutting-edge IT solutions that transform their operations, enhance their global competitiveness, and fuel their growth. We aim to be recognized as the go-to partner for companies seeking tailored, reliable, and visionary technology services.",
      "Why Choose KW Informatics": "Why Choose KW Informatics",
      Expertise: "Expertise",
      ExpertiseDescription:
        "Our team comprises experienced professionals with a deep understanding of diverse key expertise areas. From web development to software engineering, we have the skills and knowledge to tackle your most complex challenges. We stay at the forefront of the technology landscape, proficient in the latest programming languages, frameworks, and tools, ensuring that you receive top-notch solutions that are both innovative and robust.",
      "Client-Centric Approach": "Client-Centric Approach",
      ClientCentricApproachDescription:
        "We believe in transparent and open lines of communication. Your input is not just valued; it's integral to our process. We're here to listen and work closely with you. We value your feedback and tailor our services to meet your specific needs.",
      Innovation: "Innovation",
      InnovationDescription:
        "We are at the forefront of technological advancements, constantly exploring new avenues to enhance your business's efficiency, productivity, and success.",
      ContactUsDescription:
        "We invite you to get in touch with us to explore how we can help you achieve your business objectives. Whether it's a project, support services, or any technology-related need, we're here to assist you.",
      "Leave us a message!": "Leave us a message!",
      "Enter your name (required)": "Enter your name (required)",
      "example@yourcompany.com (required)":
        "example@yourcompany.com (required)",
      "General Inquiry": "General Inquiry",
      "IT Services": "IT Services",
      TransLABtor: "TransLABtor",
      "type here...": "Type here...",
      Submit: "Submit",
      "Message sent!": "Message sent!",
    },
  },
  zh_hk: {
    translation: {
      HomepageHeadline1: "我們提供度身訂造的IT解決方案",
      HomepageHeadline2: "立即發展您的業務",
      "Solutions and Services": "解決方案與服務",
      "Website Development": "網站開發",
      WebDevDes: "打造令人驚艷的、符合您品牌風格的網站，促進在線成功。",
      "Software Development": "軟件開發",
      softdevdes: "構建定制的軟件解決方案，以優化您的業務運作並提高生產力。",
      "IT Procurement": "IT採購",
      procurementdes: "採購和確保高質量的硬件和軟件，以節約IT基礎設施成本。",
      "IT Project Management": "IT項目管理",
      ITPMdes: "高效領導和執行IT項目，確保按時交付和成本控制。",
      "IT Support": "IT支援",
      ITsupdes: "快速解決IT問題，提供主動支援，保持系統平穩運行。",
      "Learn more about our offering": "了解我們的服務更多內容",
      TransLABtordes:
        "無縫翻譯化驗結果至HL7, ASTM或其他格式並自動傳送到LIS，簡化管理。",
      ReportFinderdes:
        "輕鬆訪問並通過API與服務器數據庫互動，提供直觀的過濾、排序和報告下載。",
      Home: "首頁",
      Solutions: "解決方案",
      Products: "產品",
      "About Us": "關於我們",
      "Contact Us": "聯絡我們",
      "Who We Are": "我們是誰",
      "Technical Support": "技術支援",
      "Sales Inquiry": "銷售查詢",
      Support: "支援",
      Website: "網站開發",
      Software: "軟件開發",
      Distributors: "經銷商",
      OfficeRegion: " ",
      HKOfficeAddress:
        "地址: 香港九龍荔枝角長沙灣道833號長沙灣廣場1座11樓1101室",
      CopyRightStatement:
        "© 版權所有 2023-2025 年 KW Informatics Limited。保留所有權利。",
      "Our website development services encompass a wide range of modern web technologies and practices, including JavaScript, React, and Next.js.":
        "我們的網站開發服務包括現代的Web技術和實踐，包括JavaScript、React和Next.js。",
      "We handle the entire process, from initial concept to deployment. This includes web hosting, domain name registration, and SSL certificate setup for a secure online presence.":
        "我們負責整個過程，從最初的概念到部署。這包括網站託管、域名註冊以及SSL證書設置，以實現安全的線上存在。",
      "Whether you need a sleek and informative website or a complex web application, we're well-equipped to bring your vision to life.":
        "無論您需要一個時尚且資訊豐富的網站還是一個複雜的Web應用程式，我們都有能力將您的願景實現。",
      "Our software development expertise extends to various domains, including C language, Java, and web and Windows applications.":
        "我們的軟件開發專業知識涵蓋多個領域，包括C語言、Java以及Web和Windows應用程式。",
      "We specialize in crafting custom software solutions to meet your specific needs, such as office automation, ERP systems, logistics management, and inventory control. With an emphasis on user-friendly interfaces and efficient performance, our software solutions streamline operations, increase productivity, and enhance your overall business efficiency.":
        "我們專門製作定制的軟件解決方案，以滿足您的具體需求，例如辦公自動化、ERP系統、物流管理和庫存控制。我們強調用戶友好的界面和高效的性能，我們的軟件解決方案使運營變得更高效，提高了生產率，增強了您的整體業務效率。",
      "Our IT procurement services are built on strong relationships with leading service providers and manufacturers. We're committed to sourcing the highest-quality hardware and software that align with your unique requirements. Whether you're looking for cutting-edge technology or cost-effective solutions, we'll help you secure the right IT assets for your organization.":
        "我們的IT採購服務建立在與領先的服務提供商和製造商的牢固關係之上。我們致力於採購與您獨特需求相符的最高質量的硬件和軟件。無論您是在尋找尖端技術還是具有成本效益的解決方案，我們都將幫助您為您的組織確保合適的IT資產。",
      "Our IT project management expertise ensures the successful planning, execution, and delivery of your IT projects. We excel at coordinating resources, timelines, and budgets to bring your projects to fruition. Our rigorous project management methodologies guarantee on-time and on-budget delivery, helping you achieve your IT objectives.":
        "我們的IT專案管理專業知識確保IT專案的成功規劃、執行和交付。我們擅長協調資源、時間表和預算，以實現您的專案目標。我們嚴謹的專案管理方法確保按時和按預算交付，幫助您實現您的IT目標。",
      "Our IT support services offer comprehensive technical assistance and solutions to keep your systems running smoothly. From troubleshooting issues to proactive system maintenance, we've got you covered. We pride ourselves on rapid issue resolution and providing ongoing support to optimize your IT environment, so you can focus on what you do best while leaving IT worries behind.":
        "我們的IT支援服務提供全面的技術支援和解決方案，以確保您的系統運行順利。從疑難解答到主動系統維護，我們都為您提供了全面的支援。我們以迅速解決問題並提供持續支援以優化您的IT環境而自豪，因此您可以專注於您最擅長的事情，同時不用擔心IT問題。",
      "About KW Informatics": "關於 KW Informatics",
      AboutKWInformaticsDescription:
        "在 KW Informatics，我們致力於提供卓越的度身定制 IT 解決方案，賦予企業增長的動力。我們對創新充滿激情，並致力於卓越，我們在科技和軟體開發行業建立了堅實的基礎。",
      "Our Vision": "我們的願景",
      OurVisionDescription:
        "我們的願景是通過先進的 IT 解決方案賦予企業改變其運營，增強其全球競爭力並推動增長。我們的目標是成為企業尋求度身定制、可靠和具有遠見的技術服務的首選合作夥伴。",
      "Why Choose KW Informatics": "為什麼選擇 KW Informatics",
      Expertise: "專業知識",
      ExpertiseDescription:
        "我們的團隊由經驗豐富的專業人士組成，對多個關鍵專業領域有深刻的理解。從網站開發到軟件工程，我們具備處理最復雜挑戰所需的技能和知識。我們始終處於技術領域的前沿，精通最新的編程語言、框架和工具，確保您獲得創新且強大的解決方案。",
      "Client-Centric Approach": "客戶至上的方法",
      ClientCentricApproachDescription:
        "我們相信透明和開放的溝通渠道。您的意見不僅受到重視，而且是我們工作的一部分。我們在這裡傾聽並與您緊密合作。我們重視您的反饋，並調整我們的服務以滿足您的具體需求。",
      Innovation: "創新",
      InnovationDescription:
        "我們始終處於技術進步的前沿，不斷探索新的途徑，以提高您的企業效率、生產力和成功。",
      ContactUsDescription:
        "我們邀請您與我們聯絡，以探討我們如何幫助您實現您的業務目標。無論是項目、支援服務還是任何與技術相關的需求，我們都在這裡幫助您。",
      "Leave us a message!": "留下訊息給我們！",
      "Enter your name (required)": "輸入您的名字（必填）",
      "example@yourcompany.com (required)": "example@yourcompany.com（必填）",
      "General Inquiry": "一般查詢",
      "IT Services": "IT服務",
      TransLABtor: "TransLABtor",
      "type here...": "在此輸入...",
      Submit: "提交",
      "Message sent!": "訊息已發送！",
    },
  },
  ja: {
    translation: {
      HomepageHeadline1: "カスタムITソリューションを提供",
      HomepageHeadline2: "ビジネスを成長させる",
      "Solutions and Services": "ソリューションとサービス",
      "Website Development": "ウェブサイト開発",
      WebDevDes:
        "ブランドに合わせた魅力的で使いやすいウェブサイトを作成し、オンラインで成功を収めます。",
      "Software Development": "ソフトウェア開発",
      softdevdes:
        "オペレーションを効率化し、生産性を向上させるカスタムソフトウェアソリューションを構築します。",
      "IT Procurement": "IT調達",
      procurementdes:
        "卓越したハードウェアとソフトウェアを調達し、ITインフラストラクチャを効果的に最適化します。",
      "IT Project Management": "ITプロジェクト管理",
      ITPMdes:
        "ITプロジェクトを効率的に計画、実行し、納期を守り、コストをコントロールします。",
      "IT Support": "ITサポート",
      ITsupdes:
        "ITの問題を迅速に解決し、システムをスムーズに運用します。問題の解決に自信を持ち、IT環境の最適化に向けた継続的なサポートを提供します。",
      "Learn more about our offering": "私たちの提供について詳しく学ぶ",
      TransLABtordes:
        "HL7およびASTM形式へのシームレスな翻訳と転送により、ファイルベースの分析管理が簡素化されます。",
      ReportFinderdes:
        "APIを介してサーバーデータベースに簡単にアクセスし、直感的なフィルタリング、ソート、およびレポートのダウンロードを提供します。",
      Home: "ホーム",
      Solutions: "ソリューション",
      Products: "製品",
      "About Us": "会社概要",
      "Contact Us": "お問い合わせ",
      "Who We Are": "私たちについて",
      "Technical Support": "テクニカルサポート",
      "Sales Inquiry": "販売のお問い合わせ",
      Support: "サポート",
      Website: "ウェブサイト",
      Software: "ソフトウェア",
      Distributors: "ディストリビューター",
      OfficeRegion: " ",
      HKOfficeAddress:
        "オフィス: 香港ライチコック、長沙湾ロード833、長沙湾プラザ、タワー1、11階1101号室",
      CopyRightStatement:
        "© 著作権-2025 KW Informatics Limited。全著作権所有。",
      AboutKWInformaticsDescription:
        "KW Informaticsは、ビジネスが成長できるような優れたカスタムITソリューションを提供することに専念しています。イノベーションへの情熱と卓越性へのコミットメントを持ち、技術とソフトウェア開発業界で堅固な基盤を築いています。",
      OurVisionDescription:
        "私たちのビジョンは、ビジネスに革命的なITソリューションを提供し、そのオペレーションを変革し、グローバル競争力を向上させ、成長を促進することです。私たちは、カスタマイズされた、信頼性のあるビジョン溢れるテクノロジーサービスを求める企業にとってのパートナーとして認識されることを目指しています。",
      ExpertiseDescription:
        "私たちのチームは、多様な主要な専門分野に対する深い理解を持つ経験豊富な専門家から成り立っています。ウェブ開発からソフトウェアエンジニアリングまで、最も複雑な課題に対処するスキルと知識があります。最新のプログラム言語、フレームワーク、ツールに精通しており、革新的で堅牢なソリューションを提供します。",
      ClientCentricApproachDescription:
        "透明性とオープンなコミュニケーションを信じています。あなたの意見は評価されるだけでなく、プロセスに不可欠です。私たちは聞くことと一緒に働くことが重要です。あなたのフィードバックを尊重し、サービスをあなたの特定のニーズに合わせて調整します。",
      InnovationDescription:
        "私たちは技術の進歩の最前線に立ち、ビジネスの効率、生産性、成功を向上させる新しいアプローチを常に探求しています。",
      ContactUsDescription:
        "ビジネス目標を達成するのにどのようにお手伝いできるかを探るためにお問い合わせいただくことをお勧めします。プロジェクト、サポートサービス、または技術に関連するニーズであっても、お手伝いできます。",
      "Leave us a message!": "メッセージを残す！",
      "Enter your name (required)": "お名前を入力してください（必須）",
      "example@yourcompany.com (required)": "example@yourcompany.com（必須）",
      "General Inquiry": "一般的なお問い合わせ",
      "IT Services": "ITサービス",
      TransLABtor: "TransLABtor",
      "type here...": "ここに入力...",
      Submit: "送信",
      "Message sent!": "メッセージが送信されました！",
      "About KW Informatics": "KW Informaticsについて",
      "Our Vision": "私たちのビジョン",
      "Why Choose KW Informatics": "KW Informaticsを選ぶ理由",
      Expertise: "専門知識",
      "Client-Centric Approach": "クライアント中心のアプローチ",
      Innovation: "革新",
    },
  },
  zh: {
    translation: {
      HomepageHeadline1: "我们提供量身定制的IT解决方案",
      HomepageHeadline2: "立即发展您的业务",
      "Solutions and Services": "解决方案与服务",
      "Website Development": "网站开发",
      WebDevDes: "打造令人惊艷的、符合您品牌风格的网站，促进在线成功。",
      "Software Development": "软件开发",
      softdevdes: "构建定制的软件解决方案，以优化您的业务运作并提高生产力。",
      "IT Procurement": "IT采购",
      procurementdes: "采购和确保高质量的硬件和软件，以节省IT基础设施成本。",
      "IT Project Management": "IT项目管理",
      ITPMdes: "高效领导和执行IT项目，确保按时交付和成本控制。",
      "IT Support": "IT支援",
      ITsupdes: "快速解决IT问题，提供主动支援，保持系统平稳运行。",
      "Learn more about our offering": "了解我们的服务更多内容",
      TransLABtordes:
        "无缝翻译和传送结果至HL7和ASTM格式，简化基于文件的分析仪管理。",
      ReportFinderdes:
        "轻松访问并通过API与服务器数据库互动，提供直观的过滤、排序和报告下载。",
      Home: "首页",
      Solutions: "解决方案",
      Products: "产品",
      "About Us": "关于我们",
      "Contact Us": "联系我们",
      "Who We Are": "我们是谁",
      "Technical Support": "技术支援",
      "Sales Inquiry": "销售查询",
      Support: "支援",
      Website: "网站开发",
      Software: "软件开发",
      Distributors: "经销商",
      OfficeRegion: " ",
      HKOfficeAddress:
        "地址: 香港九龙荔枝角长沙湾道833号长沙湾广场1座11楼1101室",
      CopyRightStatement:
        "© 版权所有 2023-2025 年 KW Informatics Limited。保留所有权利。",
      "Our website development services encompass a wide range of modern web technologies and practices, including JavaScript, React, and Next.js.":
        "我们的网站开发服务包括现代的Web技术和实践，包括JavaScript、React和Next.js。",
      "We handle the entire process, from initial concept to deployment. This includes web hosting, domain name registration, and SSL certificate setup for a secure online presence.":
        "我们负责整个过程，从最初的概念到部署。这包括网站托管、域名注册以及SSL证书设置，以实现安全的在线存在。",
      "Whether you need a sleek and informative website or a complex web application, we're well-equipped to bring your vision to life.":
        "无论您需要一个时尚且信息丰富的网站还是一个复杂的Web应用程序，我们都有能力将您的愿景实现。",
      "Our software development expertise extends to various domains, including C language, Java, and web and Windows applications.":
        "我们的软件开发专业知识涵盖多个领域，包括C语言、Java以及Web和Windows应用程序。",
      "We specialize in crafting custom software solutions to meet your specific needs, such as office automation, ERP systems, logistics management, and inventory control. With an emphasis on user-friendly interfaces and efficient performance, our software solutions streamline operations, increase productivity, and enhance your overall business efficiency.":
        "我们专门制作定制的软件解决方案，以满足您的具体需求，例如办公自动化、ERP系统、物流管理和库存控制。我们强调用户友好的界面和高效的性能，我们的软件解决方案使运营变得更高效，提高了生产率，增强了您的整体业务效率。",
      "Our IT procurement services are built on strong relationships with leading service providers and manufacturers. We're committed to sourcing the highest-quality hardware and software that align with your unique requirements. Whether you're looking for cutting-edge technology or cost-effective solutions, we'll help you secure the right IT assets for your organization.":
        "我们的IT采购服务建立在与领先的服务提供商和制造商的牢固关系之上。我们致力于采购和确保高质量的硬件和软件，以节省IT基础设施成本。无论您是在寻找尖端技术还是具有成本效益的解决方案，我们都将帮助您为您的组织确保合适的IT资产。",
      "Our IT project management expertise ensures the successful planning, execution, and delivery of your IT projects. We excel at coordinating resources, timelines, and budgets to bring your projects to fruition. Our rigorous project management methodologies guarantee on-time and on-budget delivery, helping you achieve your IT objectives.":
        "我们的IT项目管理专业知识确保IT项目的成功规划、执行和交付。我们擅长协调资源、时间表和预算，以实现您的项目目标。我们严谨的项目管理方法保证按时和按预算交付，帮助您实现您的IT目标。",
      "Our IT support services offer comprehensive technical assistance and solutions to keep your systems running smoothly. From troubleshooting issues to proactive system maintenance, we've got you covered. We pride ourselves on rapid issue resolution and providing ongoing support to optimize your IT environment, so you can focus on what you do best while leaving IT worries behind.":
        "我们的IT支援服务提供全面的技术支援和解决方案，以确保您的系统运行顺利。从疑难解答到主动系统维护，我们都为您提供了全面的支援。我们以迅速解决问题并提供持续支援以优化您的IT环境而自豪，因此您可以专注于您最擅长的事情，同时不用担心IT问题。",
      "About KW Informatics": "关于 KW Informatics",
      AboutKWInformaticsDescription:
        "在 KW Informatics，我们致力于提供卓越的度身定制IT解决方案，赋予企业增长的动力。我们对创新充满激情，并致力于卓越，我们在科技和软件开发行业建立了坚实的基础。",
      "Our Vision": "我们的愿景",
      OurVisionDescription:
        "我们的愿景是通过先进的IT解决方案赋予企业改变其运营、增强其全球竞争力并推动增长。我们的目标是成为企业寻求度身定制、可靠和具有远见的技术服务的首选合作伙伴。",
      "Why Choose KW Informatics": "为什么选择 KW Informatics",
      Expertise: "专业知识",
      ExpertiseDescription:
        "我们的团队由经验丰富的专业人士组成，对多个关键专业领域有深刻的理解。从网站开发到软件工程，我们具备处理最复杂挑战所需的技能和知识。我们始终处于技术领域的前沿，精通最新的编程语言、框架和工具，确保您获得创新且强大的解决方案。",
      "Client-Centric Approach": "客户至上的方法",
      ClientCentricApproachDescription:
        "我们相信透明和开放的沟通渠道。您的意见不仅受到重视，而且是我们工作的一部分。我们在这里倾听并与您紧密合作。我们重视您的反馋，且调整我们的服务以满足您的具体需求。",
      Innovation: "创新",
      InnovationDescription:
        "我们始终处于技术进步的前沿，不断探索新的途径，以提高您的企业效率、生产力和成功。",
      ContactUsDescription:
        "我们邀请您与我们联系，以探讨我们如何帮助您实现您的业务目标。无论是项目、支援服务还是任何与技术相关的需求，我们都在这里帮助您。",
      "Leave us a message!": "留下消息给我们！",
      "Enter your name (required)": "输入您的名字（必填）",
      "example@yourcompany.com (required)": "example@yourcompany.com（必填）",
      "General Inquiry": "一般查询",
      "IT Services": "IT服务",
      TransLABtor: "TransLABtor",
      "type here...": "在此输入...",
      Submit: "提交",
      "Message sent!": "消息已发送！",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
